/* @flow */

import { FormOutlined, KeyOutlined } from '@ant-design/icons';
import {
  createMailCampaign,
  getCampaigns,
  getSubscribers,
  getUnsubscribers,
} from 'actions';
import {
  Button, Checkbox, Form, Input, Modal, Table, Tabs,
} from 'antd';
import * as React from 'react';
import type { Dispatch } from 'redux';
import { decrypt } from 'utils';
import { FormContainer } from './styled';

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const CheckboxOptions = [
  {
    label: 'Newsletter',
    value: 'newsletter',
    region: ['ru'],
  },
  {
    label: 'Donor',
    value: 'donors',
    region: ['ru'],
  },
  {
    label: 'Subscriber',
    value: 'subscribers',
    region: ['ru'],
  },
  {
    label: 'Ex Subscriber',
    value: 'exSubscribers',
    region: ['ru'],
  },
  {
    label: 'Newsletter',
    value: 'regionNewsletter',
    region: ['by', 'ca'],
  },
  {
    label: 'Test',
    value: 'test',
    region: ['ru', 'ca', 'by'],
  },
];

const getCheckboxOptions = (region) => CheckboxOptions.filter((o) => o.region.includes(region));

const { TabPane } = Tabs;

const getColumns = (privateKey) => [
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    width: '10%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    render: (email) => decrypt(email, privateKey),
  },
];

type Props = {
  getSubscribers(): void,
  getCampaigns(): void,
  dispatch: Dispatch<*>,
  subscriber: SubscribersType[],
  newsletter: SubscribersType[],
  donors: SubscribersType[],
  exSubscriber: SubscribersType[],
  test: SubscribersType[],
  privateKey: string,
  unsubscribers: SubscribersType[],
  campaigns: CampaignType[],
  region: string,
  form: {
    validateFieldsAndScroll: ((Error, Object) => void) => void,
    getFieldDecorator: (
      string,
      Object,
    ) => (React$Element<*>) => React$Element<*>,
  },
};

type State = {
  isModalOpen: boolean,
  modalContent: string,
};

export class RegionMailings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalContent: '',
      loading: false,
      loadingCampaigns: false,
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      loading: !props.subscriber,
      loadingCampaigns: !props.campaigns,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      region = 'ru',
    } = this.props;
    this.setState({ loading: true }, () => {
      dispatch(getSubscribers(region));
      dispatch(getUnsubscribers(region));
      dispatch(getCampaigns(region));
    });
  }

  handleOk = () => {
    this.setState(() => ({
      isModalOpen: false,
      modalContent: '',
    }));
  };

  handleSubmit = (values) => {
    const {
      dispatch,
      region = 'ru',
    } = this.props;
    dispatch(createMailCampaign({
      ...values,
      region,
    }));
  };

  getCampaignColumns = () => [
    {
      title: 'Компания',
      dataIndex: 'campaign',
      key: 'campaign',
      width: '',
    },
    {
      title: 'Тема',
      dataIndex: 'subject',
      key: 'subject',
      width: '',
    },
    {
      title: 'Текст',
      dataIndex: 'text',
      key: 'text',
      width: '',
      render: (text: string) => (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {
          }}
          style={{
            cursor: 'pointer',
            color: 'blue',
          }}
          onClick={() => this.setState(() => ({
            isModalOpen: true,
            modalContent: text,
          }))}
        >
          Показать
        </div>
      ),
    },
    {
      title: 'Список',
      dataIndex: 'list',
      key: 'list',
      width: '15%',
      render: (list: string[]) => (
        <div>
          {Array.isArray(list)
            ? list.map((item: string) => (
              <span>
                {item}
                <br />
              </span>
            ))
            : list}
        </div>
      ),
    },
    {
      title: 'Отправлено',
      dataIndex: 'sent_count',
      key: 'sent_count',
      width: '',
    },
    {
      title: 'Всего',
      dataIndex: 'target_count',
      key: 'target_count',
      width: '',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '',
      render: (createdAt: string) => {
        const date: Date = new Date(createdAt);
        return (
          <div>
            {date.toLocaleDateString()}
            <br />
            {date.toLocaleTimeString()}
          </div>
        );
      },
      sorter: (a, b) => Date.parse(a.createdAt) > Date.parse(b.createdAt),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
  ];

  render() {
    const {
      newsletter = [],
      donors = [],
      subscriber = [],
      exSubscriber = [],
      test = [],
      privateKey,
      campaigns = [],
      region,
    } = this.props;
    const {
      modalContent,
      isModalOpen,
      loading,
      loadingCampaigns,
    } = this.state;
    const columns = getColumns(privateKey);
    return (
      <div>
        <Tabs>
          <TabPane tab={`Кампании (${campaigns.length})`} key={0}>
            <Table
              loading={loadingCampaigns}
              columns={this.getCampaignColumns()}
              dataSource={campaigns}
            />
          </TabPane>
          <TabPane name="mailCampaign" tab="Создать кампанию" key={1}>
            <FormContainer>
              <Form labelCol={{ span: 2 }} labelAlign="left" onFinish={this.handleSubmit}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Выберите как минимум один список!',
                    },
                  ]}
                  name="list"
                  label="Списки"
                >
                  <CheckboxGroup options={getCheckboxOptions(region)} />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Поле обязательно для заполнения!',
                    },
                  ]}
                  name="subject"
                  label="Тема"
                >
                  <Input prefix={<FormOutlined />} />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Поле обязательно для заполнения!',
                    },
                    {
                      pattern: /^[0-9a-zA-Z_]+$/,
                      message: 'Только латинские буквы и цифры',
                    },
                    {
                      min: 3,
                      message: 'Не меньше 3 символов',
                    },
                    {
                      max: 20,
                      message: 'Не больше 20 символов',
                    },
                  ]}
                  name="campaign"
                  label="Кампания"
                >
                  <Input prefix={<KeyOutlined />} />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Поле обязательно для заполнения!',
                    },
                  ]}
                  name="text"
                  label="Текст"
                >
                  <TextArea rows={15} allowClear autosize />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Создать
                </Button>
              </Form>
            </FormContainer>
          </TabPane>
          <TabPane tab={`Рассылка (${newsletter.length})`} key={2}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={newsletter}
            />
          </TabPane>
          {region === 'ru' && (
          <>
            <TabPane tab={`Доноры (${donors.length})`} key={3}>
              <Table loading={loading} columns={columns} dataSource={donors} />
            </TabPane>
            <TabPane tab={`Подписчики (${subscriber.length})`} key={4}>
              <Table
                loading={loading}
                columns={columns}
                dataSource={subscriber}
              />
            </TabPane>
            <TabPane
              tab={`Бывшие подписчики (${exSubscriber.length})`}
              key={5}
            >
              <Table
                loading={loading}
                columns={columns}
                dataSource={exSubscriber}
              />
            </TabPane>
          </>
          )}
          <TabPane tab={`Тесты (${test.length})`} key={6}>
            <Table loading={loading} columns={columns} dataSource={test} />
          </TabPane>
        </Tabs>
        <Modal
          width="70%"
          visible={isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleOk}
        >
          <p>{modalContent}</p>
        </Modal>
      </div>
    );
  }
}

export default RegionMailings;
